import * as React from "react"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import * as classes from "./MainNav.module.scss"

const MainNav = () => {
    return (
        <>
          <Container>
          <Row>
            <Col> 
            <Navbar  variant="dark" expand="lg">
              <Container>
                <Navbar.Brand href="/" className={classes.brandSpacing}>
                  <span style={{fontSize:"30px",fontWeight:700}}>KEVIN</span>
                  <span style={{fontSize:"30px",color:"#007F79"}}>BLANK</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    
                  </Nav>
                  <Nav>
                    <Nav.Link href="/about" className={classes.mainnav}>About</Nav.Link>
                    {/*<Nav.Link href="#link" className={classes.mainnav}>Thoughts</Nav.Link>*/}
                    <Nav.Link href="/portfolio" className={classes.mainnav}>Portfolio</Nav.Link>
                    <Nav.Link href="/work" className={classes.mainnav}>Work</Nav.Link>
                    <Nav.Link href="/contact" className={classes.mainnav}>Contact</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            </Col>
          </Row>
        </Container>
           
            
        </>
    );
}

export default MainNav