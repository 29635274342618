import * as React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"



import MainNav from "./MainNav"

const Layout = ({children}) => {
    return (
        <>
            <header>
                <div style={{backgroundColor:"#333333"}}>
                <MainNav />
                </div>
            </header>

            {children}

            <footer>
            <Container style={{marginTop:"60px"}}>
                <Row>
                    <Col style={{textAlign:"center", color: "#CCC"}}>
                        © kevinblank.com {new Date().getFullYear()}
                    </Col>
                </Row>
            </Container>
            </footer>
        </>
    )
}

export default Layout